import dynamic from "next/dynamic";
import Typography from "@mui/material/Typography";

const FooterLink = dynamic(() => import("./FooterLink"))
const FooterAccordion = dynamic(() => import("./FooterAccordion"))


const FooterCompany = ({links, type}) => {
    return (
        type == 'desktop' ? (
            <>
                <Typography className="f-size_3 mb-3 uppercase font-regular" component="h5">Company</Typography>
                <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"company_link-" + index}
                                />
                            );
                        })
                    }
                </ul>
            </>
        ) : (
            <FooterAccordion classes="fw-bold font-regular" title="Company" accordionType="company" type="footer">
               <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"company_link-" + index}
                                />
                            );
                        })
                    }
                </ul>
            </FooterAccordion>
        )
    );
};

export default FooterCompany;
